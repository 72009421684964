.QjetwMjCjmyrbgULRrpKP {
  font-weight: bold;
  font-size: 16px; }

.abpBg5OR3yH_6sphOmM_X {
  display: block; }

._1Ne-DE90rz53oZffN_s7gB {
  display: none; }

.tP88QsS1g3M2Od6nyWxQE {
  padding-top: 0.4rem; }
  .tP88QsS1g3M2Od6nyWxQE ._3a1pBENCYA878u--qyEAFz {
    color: #636363;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 5px;
    width: 15px; }
  .tP88QsS1g3M2Od6nyWxQE h3 {
    font-size: 1rem; }

._1D2YhuMK1jHihal_yKenoe {
  background: white;
  border: solid 1px #cccccc;
  max-height: 100vh;
  padding: 35px 10px 10px;
  position: absolute;
  top: 80px;
  width: 300px;
  z-index: 200;
  color: #636363; }

@media (max-width: 768px) {
  ._1D2YhuMK1jHihal_yKenoe {
    left: -80px; } }
