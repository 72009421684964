._2U1EBtbnuDYP3loLWet1OV {
  font-size: 2rem; }

._3UPwjBNsIZ3FD64GFBoxz {
  background-color: #eeeeee;
  font-size: 0.9rem;
  padding: 10px; }

._1nDT4UzC9cGdnp0fl9QJZF {
  width: 16px; }

.eIidqMifYHqh-Ri-TN1w3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px; }
  .eIidqMifYHqh-Ri-TN1w3 img {
    width: 35px; }

._2gcPlGhtn25NGAvqiWkWY1 {
  font-size: 15px !important;
  text-decoration: underline; }

._2gcPlGhtn25NGAvqiWkWY1:hover {
  text-decoration: none !important; }

._3wtWBCrn1xwI77FZuOjhrA {
  display: flex;
  align-items: end;
  justify-content: space-between; }

._2TSRGpq86MXGGBuyFIwchN {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.9rem; }

@media (max-width: 768px) {
  ._3UPwjBNsIZ3FD64GFBoxz {
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.8rem; }
  ._2TSRGpq86MXGGBuyFIwchN {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem; } }

._2lvhwpUXEM_i1lLnggR_pi {
  font-size: 0.8em;
  margin-bottom: -15px; }
  ._2lvhwpUXEM_i1lLnggR_pi a {
    cursor: pointer; }

.J9QnftVWG9aSmUJtQjcwE {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #005983; }

a {
  color: #005983; }

._2UmsW6d2sVjyuE2UXnCPnx {
  position: relative;
  display: inline-block; }

._2UmsW6d2sVjyuE2UXnCPnx ._3nWeiI-CRYf6EyZpCNSYPU,
._3e33Zj0HiOS-hlntFC7tcO ._3nWeiI-CRYf6EyZpCNSYPU {
  width: 300px;
  background-color: black;
  text-align: left;
  padding: 0.5em;
  background-color: #f4f4f4;
  line-height: 1.1rem;
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  z-index: 1; }

._2UmsW6d2sVjyuE2UXnCPnx ._3nWeiI-CRYf6EyZpCNSYPU {
  visibility: hidden;
  top: 100%; }

._2UmsW6d2sVjyuE2UXnCPnx:hover ._3nWeiI-CRYf6EyZpCNSYPU {
  visibility: visible; }

._3e33Zj0HiOS-hlntFC7tcO {
  display: none; }

._3Lcq8lba-n3vgrZjvE75DU {
  display: flex;
  align-items: center; }

.d-vg6aSsk4if1n7iSARdu {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #636363;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer; }

._3PkVdm3c87zJdqd94lmlo5 {
  cursor: pointer; }

._3Clfcthn32TC857c_pE36U {
  margin-left: 5px; }

._3UlrLan1ZciL0lQIuzycBX {
  margin-top: 10px;
  margin-bottom: 10px; }

._2RW9VKes19YuxqSXfQ6jyu ._35-niyEtCXkJ64jiWCRj-o {
  line-height: 1.7rem; }

._10nbMmPco3ylzMCouEAwwr {
  font-weight: bold;
  padding-bottom: 1rem; }

._1j-wLxTpBrlISxOBaVUfFJ {
  font-size: 1.2rem; }

._1mWp_CwLg1Xiri5k-jEzpJ {
  display: flex;
  flex-direction: row; }

._1mWp_CwLg1Xiri5k-jEzpJ,
.JuFP93HUYULfLAket1v4B {
  column-count: 2; }

._1ya31cIatMtUNEHSSNVUlw {
  font-weight: bold;
  text-decoration: underline; }

@media (max-width: 500px) {
  ._2UmsW6d2sVjyuE2UXnCPnx {
    display: none; }
  ._3e33Zj0HiOS-hlntFC7tcO {
    display: inline-block; }
  .JuFP93HUYULfLAket1v4B {
    column-count: 1; } }

._1L9iTbw2k9qYhWyHIgK5fi {
  position: relative;
  height: 92px;
  width: 100%;
  background-color: lightgray;
  border-radius: 5px; }
  ._1L9iTbw2k9qYhWyHIgK5fi._3q51EhzmCnGKfvitOVzz6R {
    margin: 5px 0px;
    height: 50px;
    width: 70%; }
  ._1L9iTbw2k9qYhWyHIgK5fi._3CmXc8FBB8qcdF09xLU6vN {
    margin: 5px 0px;
    height: 20px;
    width: 80%; }
  ._1L9iTbw2k9qYhWyHIgK5fi ._3t7jCo6BloAqmirZgStaz- {
    height: 100%;
    width: 100%;
    position: absolute;
    left: -45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    animation: _1L9iTbw2k9qYhWyHIgK5fi 1s infinite; }

@keyframes _1L9iTbw2k9qYhWyHIgK5fi {
  0% {
    left: -25%; }
  100% {
    left: 100%; } }

._3jqrYrHCBOhW-qGOI7icUh {
  margin-top: 10px; }

.lv7LEse3TfqPw1xVKXTDj {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px; }

._2tvi0Oz6u_xrGnRkh4I0AI {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 7px;
  gap: 15px;
  margin-right: 15px;
  margin-top: 15px; }
  ._2tvi0Oz6u_xrGnRkh4I0AI ._3XYRAVhmjybMV5nQmM5288 {
    display: flex;
    flex-direction: row;
    gap: 5px; }
    ._2tvi0Oz6u_xrGnRkh4I0AI ._3XYRAVhmjybMV5nQmM5288 ._35pZafcgCZ0DV76oVEoJlk {
      display: flex;
      gap: 5px;
      flex-direction: column;
      flex: 1 1 0px;
      align-items: center; }
      ._2tvi0Oz6u_xrGnRkh4I0AI ._3XYRAVhmjybMV5nQmM5288 ._35pZafcgCZ0DV76oVEoJlk ._2vrQbPWtDhOzKAduNFsRjC {
        max-width: 45px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: 0 0 10px; }
        ._2tvi0Oz6u_xrGnRkh4I0AI ._3XYRAVhmjybMV5nQmM5288 ._35pZafcgCZ0DV76oVEoJlk ._2vrQbPWtDhOzKAduNFsRjC svg {
          width: 30px;
          opacity: 1;
          padding: 0 !important;
          height: 25px;
          margin: 0 !important;
          color: #444;
          fill: #444; }
      ._2tvi0Oz6u_xrGnRkh4I0AI ._3XYRAVhmjybMV5nQmM5288 ._35pZafcgCZ0DV76oVEoJlk ._2dKWXqKODrLd3MwhfNJtUf {
        font-size: 14px;
        text-align: center; }
        ._2tvi0Oz6u_xrGnRkh4I0AI ._3XYRAVhmjybMV5nQmM5288 ._35pZafcgCZ0DV76oVEoJlk ._2dKWXqKODrLd3MwhfNJtUf ._1fBGU_oZnzCO1CWMuHvoY0 {
          display: inline;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          line-height: 1.2; }
          ._2tvi0Oz6u_xrGnRkh4I0AI ._3XYRAVhmjybMV5nQmM5288 ._35pZafcgCZ0DV76oVEoJlk ._2dKWXqKODrLd3MwhfNJtUf ._1fBGU_oZnzCO1CWMuHvoY0 button {
            display: block !important;
            border: none !important;
            background: transparent !important;
            color: #007ea8 !important;
            line-height: 1 !important;
            padding: 0;
            margin: 10px auto 0;
            cursor: pointer !important; }

._109w0N4riy5fohAINVNEhU {
  height: 100%;
  width: 450px !important;
  top: 0 !important;
  transform: translateX(0%) !important;
  right: 0 !important;
  left: auto !important;
  position: fixed;
  background: #f7f7f7 !important;
  overflow-y: scroll; }
  ._109w0N4riy5fohAINVNEhU .kQZFplDzydwUvRM2YdWW9 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 30px;
    padding-left: 15px;
    background: #f7f7f7 !important; }
  ._109w0N4riy5fohAINVNEhU ._2ekYcQFQR0halYq0E4FFkN {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    background: #fff; }
    ._109w0N4riy5fohAINVNEhU ._2ekYcQFQR0halYq0E4FFkN ._3FALd2hVPMiphGhR8qIhHV {
      position: relative;
      cursor: pointer; }
      ._109w0N4riy5fohAINVNEhU ._2ekYcQFQR0halYq0E4FFkN ._3FALd2hVPMiphGhR8qIhHV ._3n0xQpavTKqT8mVp9vY9yE {
        font-size: 16px;
        font-style: italic;
        font-weight: normal;
        margin-bottom: 20px;
        color: #242323;
        max-width: 280px;
        margin-right: 100px; }
    ._109w0N4riy5fohAINVNEhU ._2ekYcQFQR0halYq0E4FFkN ._2NtpM0EF1mG9_S74B3mJAd {
      margin-right: 20px; }
      ._109w0N4riy5fohAINVNEhU ._2ekYcQFQR0halYq0E4FFkN ._2NtpM0EF1mG9_S74B3mJAd ._JDr1pHTBCLAE6K5BKEHY {
        font-size: 16px;
        font-weight: bolder;
        color: #515151; }
      ._109w0N4riy5fohAINVNEhU ._2ekYcQFQR0halYq0E4FFkN ._2NtpM0EF1mG9_S74B3mJAd ._2c4RwtambfCa-SnV1BlS-8 {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 18px;
        color: #242323; }

@media (max-width: 768px) {
  ._109w0N4riy5fohAINVNEhU {
    width: 100% !important; } }
