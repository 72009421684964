._3-w2dMuxWOdO3i3UG5HpLM {
  background-color: rgba(0, 0, 0, 0.6);
  left: 0%;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0%;
  z-index: 5000; }

._1Q3KRmOHjHMpAeL3ggFV-W {
  background: white;
  left: 50%;
  min-height: 200px;
  position: fixed;
  top: 30%;
  transform: translateX(-50%);
  transition: 0.3s all;
  width: 500px;
  z-index: 2147482998;
  padding: 10px; }
  ._1Q3KRmOHjHMpAeL3ggFV-W ._325grilFWY39K-lr2Zi0VV {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-bottom: 30px;
    width: 11px; }
  ._1Q3KRmOHjHMpAeL3ggFV-W ._2uoYPOl87vZkuwnDYIAfcG {
    background: white;
    font-size: 0.8rem;
    margin-top: 30px;
    position: relative; }

._28Fb1jv2Gggp5-q42JN32x {
  display: flex;
  justify-content: space-between;
  width: 50%; }

.G6o06bHNE858BnHhMYThn {
  color: #eb0000;
  width: 20px;
  height: 20px; }

._2dSEdOGcwWZF_0KhKss5-g {
  color: #eb0000;
  font-weight: bold; }

@media (max-width: 768px) {
  ._1Q3KRmOHjHMpAeL3ggFV-W {
    width: 90%; }
  ._28Fb1jv2Gggp5-q42JN32x {
    width: 70%; } }
