._3Ck3hBz84-DUqeTw0Omv0U {
  display: flex;
  justify-content: center; }

._2SEjsU1sG-T_MDfkhwuyDE {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; }

._2UZzLzPCABCsx79CLO7ak {
  border-top: 0.1rem solid #d5d5d5;
  padding: 1rem 0;
  width: 100%;
  text-align: center;
  cursor: pointer; }
  ._2UZzLzPCABCsx79CLO7ak .wqJD13tyaWIY8bW6D6KQB {
    width: 16px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 4px; }

a._2UZzLzPCABCsx79CLO7ak {
  text-decoration: none;
  color: black; }

a._2UZzLzPCABCsx79CLO7ak:hover {
  text-decoration: underline; }

._2yq9xbtB9qBz5igoCX_9fv {
  width: 100%;
  border: 1px solid #fff;
  background-color: #e5f0f4 !important;
  height: 2.5rem;
  margin-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  line-height: 1; }

._1JNCmAEV8B_m4XYrxqN8rl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  ._1JNCmAEV8B_m4XYrxqN8rl input {
    width: 90%;
    text-align: left;
    padding-left: 10px; }
  ._1JNCmAEV8B_m4XYrxqN8rl a {
    color: black;
    margin-right: 30px; }

.eTr4rHGqB1VfBx6PZzEC {
  border: 1px solid #fff;
  width: 90%;
  padding-left: 10px;
  padding-right: 25px;
  background-color: #e5f0f4 !important; }
